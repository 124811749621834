import React from "react"
import { Link, graphql } from "gatsby"
import HeaderSecondary from "../components/headerSecondary"
import FooterGlobal from "../components/FooterGlobal"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import ReadyStarted from "../components/ReadyStarted"
import Layout from "../components/layout"
import Moment from "react-moment"
import SEO from "../components/seo"
import Subscribe from "../components/Subscribe"
import MainNav from "../components/MainNav"

export const blogData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "blog") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
      posts(first: 20) {
        blogPosts: nodes {
          title
          content
          date
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
      posts: { blogPosts },
    },
  } = data

  return (
    <Layout
      announcement={!announcement ? "" : announcement}
      countdownData={!countdownData ? "" : countdownData}
    >
      <SEO title={sitemetatitle} description={siteMetaDescription} />
      <MainNav active="blog" />
      <HeaderSecondary
        heading=" Internet marketing starts here"
        subheading=" Subscribe to get the best digital marketing insights, strategies and tips delivered straight to your inbox."
      />
      <section className="blog container">
        <ul className="blog__list list-unstyled">
          {blogPosts.map((item, index) => (
            <li key={index} className="blog__item">
              <div className="blog__imgWrapper">
                <img
                  src={item.featuredImage.node.sourceUrl}
                  alt={item.title}
                  className="blog__img"
                />
              </div>
              <div className="blog__content">
                <p className="blog__content--date">
                  <i className="far fa-calendar-alt"></i>
                  <Moment format="MMMM DD, YYYY">{item.date}</Moment>
                </p>
                <h2 className="blog__content--title">{item.title}</h2>

                <div
                  className="blog__content--description"
                  dangerouslySetInnerHTML={{
                    __html: item.content.slice(0, 300) + `[...]`,
                  }}
                ></div>
                <div className="blog__content--view">
                  <Link
                    to={item.slug}
                    className="blog__content--btn btn btn-primary"
                  >
                    View Full Story
                    <i className="blog__content--btnIcon fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <Subscribe />
        <ReadyStarted color="primary" />
        <BookStrategyBtnPrimary />
      </section>

      <FooterGlobal
        companyAddress={companyAddress}
        companyContactNumber={companyContactNumber}
        companyOverview={companyOverview}
        facebookLink={facebookLink}
        linkedinLink={linkedinLink}
        supportEmail={supportEmail}
        twiiterLink={twiiterLink}
      />
    </Layout>
  )
}

export default Blog
