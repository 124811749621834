import { Link } from "gatsby"
import Moment from "react-moment"
import React from "react"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"
// import PropTypes from "prop-types"
const HeaderSecondary = ({
  heading,
  subheading,
  active,
  date,
  featuredImage,
}) =>
  //   {
  //   siteTitle,
  //   heading,
  //   subheading,
  //   contact,
  //   background,
  //   }
  {
    const color1 = "to right bottom, rgba(0, 0, 0, 0.9)"
    const color2 = "rgba(0, 0, 0, 0.8)"
    const str = "linear-gradient(" + color1 + "," + color2 + "),"

    const customStyle = {
      backgroundImage: str + "url(" + require("../images/team.jpg") + ")",
      backgroundRepeat: "no-repeat",
    }

    // const handleContactHeaderSecondary = e => {
    //   e.preventDefault()

    //   // Lets track that custom click
    //   trackCustomEvent({
    //     // string - required - The object that was interacted with (e.g.video)
    //     category: "ContactPageHeaderSecondary",
    //     // string - required - Type of interaction (e.g. 'play')
    //     action: "Click",
    //     // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
    //     label: "Contact Header Secondary",
    //   })
    // }

    return (
      <header className="header-secondary container" style={customStyle}>
        <div className="header-secondary__wrapper  ">
          {/* <nav className="nav">
            <ul className="nav__list--left list-unstyled">
              <li className="nav__list--left--item">
                <Link className={`nav__list--left--link`} to="/">
                  Home
                </Link>
              </li>
              <li className="nav__list--left--item">
                <Link
                  className={` ${
                    active === "about"
                      ? "nav__list--left--link--active"
                      : "nav__list--left--link"
                  }`}
                  to="/about"
                >
                  Why Us
                </Link>
              </li>
              <li className="nav__list--left--item">
                <Link
                  className={` ${
                    active === "portfolio"
                      ? "nav__list--left--link--active"
                      : "nav__list--left--link"
                  }`}
                  to="/portfolio"
                >
                  Our Work
                </Link>
              </li>
              <li className="nav__list--left--item">
                <span className="nav__list--left--link">Services</span>
                <ul className="nav__list--left--item--sublist">
                  <li className="nav__list--left--item--subItem">
                    <Link
                      className="nav__list--left--item--subLink"
                      to="/webdesign-philippines"
                    >
                      Web Design
                    </Link>
                  </li>
                  <li className="nav__list--left--item--subItem">
                    <Link
                      className="nav__list--left--item--subLink"
                      to="/seo-philippines"
                    >
                      SEO (Google Ranking)
                    </Link>
                  </li>
                  <li className="nav__list--left--item--subItem">
                    <Link
                      className="nav__list--left--item--subLink"
                      to="/google-reviews"
                    >
                      SEO (Google Reviews)
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav__list--left--item">
                <Link
                  className={` ${
                    active === "blog"
                      ? "nav__list--left--link--active"
                      : "nav__list--left--link"
                  }`}
                  to="/blog"
                >
                  Blog
                </Link>
              </li>
            </ul>

            <ul className="nav__list--right list-unstyled">
              <li>
                <Link className="nav__list--right--link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav> */}

          <div className="nav__right--icon">
            <i className="fas fa-align-right"></i>
          </div>

          <div className="hero-secondary">
            {/* <h1 className="hero__heading">
            Business
            <span className="hero__heading--highlight">Website</span>
            That Drives Traffic and Get Better Results
          </h1> */}
            <h1 className="hero-secondary__heading">
              {heading}
              {/* We Build More Than Just a Website */}
            </h1>
            <h2 className="hero-secondary__subheading">
              {subheading}
              {/* It's great to have a pretty website, but it;s even better if that
              website is bringing in more money right? */}
            </h2>
            <p className="hero-secondary__date">
              <i className="hero-secondary__date--icon far fa-calendar-alt"></i>
              {date && <Moment format="MMMM DD, YYYY">{date}</Moment>}
            </p>
          </div>
        </div>
      </header>
    )
  }

// HeaderSecondary.propTypes = {
//   siteTitle: PropTypes.string,
// }

// HeaderSecondary.defaultProps = {
//   siteTitle: ``,
// }

export default HeaderSecondary
